import React, { Fragment } from 'react';

const Text: React.FC<{ style?: any }> = ({ style = {}, children }) => {
  if (typeof children !== 'string') return null;

  return (
    <span style={style}>
      {children
        .trim()
        .split('\n')
        .map((item, key) => (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        ))}
    </span>
  );
};

export default Text;
