import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import image from '../img/kontakt.jpg';
import Text from '../components/Text';
import { FiGlobe, FiMail, FiPhone } from 'react-icons/fi';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import BackButton from '../components/BackButton';

export const ContactPageTemplate: React.FC<any> = ({
  contact,
  openingHours,
  application,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 1, flexDirection: 'column' }}>
        <div style={{ marginBottom: '50px' }}>
          <PageTitle>{contact.title}</PageTitle>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontSize: '18px' }}>{contact.address}</Text>
            <br />
            <ContactRow>
              <FiPhone />
              <a
                href={`tel:${contact.phone}`}
                style={{ color: 'black', textDecoration: 'none' }}
              >
                {contact.phone}
              </a>
            </ContactRow>
            <ContactRow>
              <FiMail />
              <a
                href={`mailto:${contact.email}`}
                style={{ color: 'black', textDecoration: 'none' }}
              >
                {contact.email}
              </a>
            </ContactRow>
            <ContactRow>
              <FiGlobe />
              <span>{contact.web}</span>
            </ContactRow>
          </div>
        </div>
        <div style={{ marginBottom: '50px' }}>
          <PageTitle>{openingHours.title}</PageTitle>
          <Text>{openingHours.text}</Text>
        </div>
        <div>
          <PageTitle>{application.title}</PageTitle>
          <Text>{application.text}</Text>
        </div>
        <BackButton
          to="/"
          style={{ marginTop: '40px' }}
          text="Zurück zur Startseite"
        />
      </div>
      {!isTabletOrMobile && <img src={image} style={{ height: '100%' }} />}
    </div>
  );
};

const ContactRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;

  svg {
    font-size: 24px;
    margin-right: 20px;
  }

  span,
  a {
    font-size: 18px;
  }
`;

const ContactPage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ContactPageTemplate
        contact={post.frontmatter.contact}
        openingHours={post.frontmatter.openingHours}
        application={post.frontmatter.application}
      />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        contact {
          title
          address
          phone
          email
          web
        }
        openingHours {
          title
          text
        }
        application {
          title
          text
        }
      }
    }
  }
`;
